.App {
  font-family: sans-serif;
  text-align: center;
}
.montserrat-100 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}
.montserrat-300 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.montserrat-500 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.bebas-neue {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}
body {
  background-color: #1c1c1e;
  color: #fff;
  font-size: 14px;
}

a,
.green-color {
  color: #6cd38d;
}
.white-color {
  color: #fff;
}
.maxWidth130 {
  max-width: 130px;
}
.textRight {
  text-align: right;
}
.textLogo {
  font-size: 50px;
}
.bg-black {
  background-color: #1c1c1e;
}
.p-15 {
  padding: 15px;
}
.p-7 {
  padding: 7px;
}
.subHeader {
}
.subHeader a {
  padding-left: 5px;
  padding-right: 5px;
}
